@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap");

$supa-red: #f0464f;
$supa-orange: #f7c458;
$supa-green: #9cb983;
$supa-blue: #667bb1;

$supa-red-50: rgba(240, 70, 79, 0.8);
$supa-orange-50: rgba(247, 196, 88, 0.8);
$supa-green-50: rgba(156, 185, 131, 0.8);
$supa-blue-50: rgba(102, 123, 177, 0.8);

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

svg {
  width: 100%;
  height: 100px;
}

.o-hidden {
  overflow: hidden;
}

svg.polygon-divider {
  display: block;
}

html,
body {
  min-height: calc(100% - 50px);
  height: calc(100% - 50px);
  font-size: 22px;

  div.new-story-promo {
    opacity: 1;
    transition: opacity 0.1s linear;

    &.promo-hide {
      opacity: 0;
    }

    div.promo-content-wrapper {
      width: 400px;
      height: 100%;
      display: flex;
      gap: 15px;
    }

    font-family: "Jost", sans-serif;
    height: 150px;
    width: 100%;
    background-color: $supa-blue;
    color: white;
    position: fixed;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 8px 15px;

    div.information-text {
      max-width: 50%;
      p.promo-header {
        font-size: 16px;
      }

      p.promo-title {
        margin-top: 6px;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
      }

      div.date {
        p {
          line-height: 20px;
          margin-top: 15px;
        }
      }

      div.read-more-button {
        border-radius: 10px;
        height: 40px;
        border: 3px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        position: absolute;
        bottom: 10px;
        padding: 5px 25px;
        font-size: 20px;
      }
    }

    div.teaser-image {
      position: relative;
      img {
        position: absolute;
        bottom: 0;
        width: 150px;
        border-radius: 8px;
        border: 5px solid white;
      }
    }
  }

  div.menu-overlay {
    width: 100%;
    height: calc(
      100% + 100px
    ); // dirty hack to compensate for address bar on mobile
    background-color: white;
    position: absolute;
    z-index: 300;
    display: flex;
    flex-direction: column;

    .menu-footer {
      margin-top: auto;
      margin-bottom: 100px; // workaround for dirty hack above
    }

    div.menu-header {
      display: flex;
      align-items: center;
      background-color: white;
      padding: 15px 0;

      div.overlay-close-trigger {
        margin-left: auto;
        margin-right: 25px;
        font-size: 32px;
        color: $supa-red;
        width: 45px;
        height: 45px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.25s, background-color 0.25s;
        cursor: pointer;

        &:hover {
          background-color: $supa-red;
          color: white;
        }
      }

      div.logo-small {
        max-height: 45px;

        img {
          margin-left: 25px;
          max-height: 50px;
        }
      }
    }

    div.menu-teaser {
      background-color: $supa-orange;
      width: 100%;
      padding: 15px 25px;

      h2 {
        color: white;
        max-width: 50px;
      }
    }
    div.latest-story {
      display: flex;
      background-color: $supa-red;
      max-height: 170px;
      overflow: visible;

      h2 {
        margin-left: 25px;
        font-size: 32px;
        color: white;
        max-width: 20%;
        line-height: 30px;
        align-self: center;
      }

      img.thumbnail {
        border-radius: 18px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        display: block;
        border: 5px solid $supa-blue;
      }
    }
    div.menu-navigation {
      width: 100%;
      flex: 1;
      ul {
        display: flex;
        flex-direction: column;
        height: 100%;
        li {
          //height: 100px;
          flex: 1;
          font-weight: 500;
          font-size: 48px;
          color: white;
          font-family: "Jost", sans-serif;
          display: flex;
          align-items: center;
          padding-left: 25px;
          cursor: pointer;

          i {
            margin-left: auto;
            margin-right: 25px;
          }
        }

        li.red {
          background-color: $supa-red;
          &:hover {
            background-color: $supa-red-50;
          }
        }

        li.orange {
          background-color: $supa-orange;
          &:hover {
            background-color: $supa-orange-50;
          }
        }

        li.green {
          background-color: $supa-green;
          &:hover {
            background-color: $supa-green-50;
          }
        }

        li.blue {
          background-color: $supa-blue;
          &:hover {
            background-color: $supa-blue-50;
          }
        }
      }
    }
  }

  div.menu-overlay.show {
    visibility: visible;
  }

  div.menu-overlay.hide {
    visibility: hidden;
    display: none;
  }

  div.navbar {
    width: 100%;
    height: 50px;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 150;
    display: flex;
    align-items: center;
    //justify-content: flex-end;
    // visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.1s linear;
    // border-bottom: 3px solid $supa-blue;

    .hamburger-trigger {
      margin-left: auto;
      margin-right: 25px;
      font-size: 32px;
      color: $supa-red;
      width: 45px;
      height: 45px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color 0.25s, background-color 0.25s;
      cursor: pointer;

      &:hover {
        background-color: $supa-red;
        color: white;
      }
    }

    .logo-small {
      img {
        max-height: 45px;
        margin-left: 20px;
        margin-top: auto;
        margin-bottom: auto;
        display: block;
      }
    }
    .symbols {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      align-items: center;
      margin-right: 25px;

      .symbol {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        color: $supa-red;
        font-size: 40px;
        border: 2px solid $supa-red;
        border-radius: 14px;
      }
    }
  }

  div.navbar.d-none {
    // visibility: hidden;
    opacity: 0;
    //transition: visibility 0s, opacity 0.25s linear;
  }

  div.visual-ring {
    position: absolute;
    right: 25px;
    z-index: 0;
    transform: translate(0, -200px) scale(0.6);
  }
  header {
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100vw;
    max-width: 100%;
    // background-color: #9db7fa;
    background-color: $supa-red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div.intro {
      padding: 0 25px 0 25px;

      img {
        max-width: 80%;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    div.introduction {
      font-family: "Jost", sans-serif;
      font-weight: 500;
      font-size: 28px;
      padding: 0 25px;
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        margin-bottom: 20px;
        text-align: center;
      }
      a.button {
        // display: block;
        // width: 100%;
        min-height: 75px;
        border-color: $supa-orange;
        border: none;
        background-color: $supa-orange;
        text-decoration: none;
        color: white;
        font-weight: 500;
        font-size: 32px;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        max-width: 500px;

        i {
          margin-left: 20px;
        }
      }

      div.header-symbols-container {
        margin-bottom: 60px;
        div.symbols {
          margin-bottom: 15px;
          gap: 15px;
          a {
            color: white;
            div.symbol {
              height: 30px;
              width: 30px;
              font-size: 30px;
            }
          }
        }
      }
    }

    div.scroll-indicator {
      font-size: 58px;
      position: absolute;
      bottom: 0;
    }

    div.symbols {
      display: flex;
      justify-content: center;
      align-items: center;

      div.symbol {
        font-size: 48px;
        // width: 50px;
        // height: 50px;
        width: 50px;
        border: 2px solid white;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }
    }
  }

  svg.polygon-divider {
    z-index: 10;
    position: relative;
    polygon,
    path {
      fill: $supa-red;
    }
  }

  svg.polygon-divider.blue {
    polygon,
    path {
      fill: $supa-blue;
    }
  }

  div.section-light {
    background-color: white;
    h1 {
      color: $supa-red;
      font-size: 72px;
      text-align: center;
      margin-bottom: 15px;
    }

    p {
      font-family: "Jost", sans-serif;
      color: $supa-red;
      padding: 0 25px;
      font-weight: 500;
    }
  }

  div.section-light.story-section {
    .intro {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-bottom: 25px;
      padding: 0;
      padding: 0 25px;
    }
    .button {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      border-radius: 18px;
      margin-bottom: 25px;
      max-width: 100%;
    }

    div.visual-bow {
      z-index: 0;
      position: absolute;
      left: 25px;
      bottom: -300px;
      transform: rotate(10deg);

      img {
        max-width: 40%;
        margin: 0;
      }
    }

    div.button:last-of-type {
      margin-bottom: 25px;
    }

    div.story {
      width: 100%;
      background-color: $supa-blue;
      padding-bottom: 20px;
      padding-top: 20px;

      .content {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }

      .story-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      h2,
      h3 {
        color: white;
        font-size: 52px;
        text-align: center;
        margin-bottom: 25px;
        flex-grow: 0;
      }

      h3.story-year,
      h4.release-date-info {
        font-size: 16px;
        margin-bottom: 0;
        font-family: "Jost", sans-serif;
        margin-top: 15px;
        color: $supa-blue;
        background-color: white;
        display: inline;
        padding: 5px 10px;
        border-radius: 8px;
      }

      h4.release-date-info {
        background-color: $supa-blue;
        color: white;
      }

      .description {
        width: 100%;
        display: flex;
        align-items: center;

        a {
          display: block;
          width: 40%;
        }

        img,
        picture {
          max-width: 40%;
          margin-right: 25px;
          cursor: pointer;
        }

        p {
          color: white;
        }

        .description-text-container {
          h4 {
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: fit-content;
            margin-bottom: 15px;
          }
        }
      }

      a.story-link {
        margin-left: auto;
        margin-right: auto;
        width: calc(50% - 15px);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Jost", sans-serif;
        text-decoration: none;
        font-weight: 500;
        font-size: 32px;
        border: 2px solid white;
        border-radius: 12px;
        padding: 15px;
        transition: color 0.25s, background-color 0.25s;

        &:hover {
          background-color: white;
          color: $supa-blue;
        }
      }
    }

    div.story.alt {
      position: relative;
      z-index: 10;
      background-color: $supa-red;

      a.story-link {
        &:hover {
          color: $supa-red;
        }
      }

      h3.story-year {
        color: $supa-red;
      }

      div.description {
        flex-direction: row-reverse;
      }

      img {
        margin-right: unset;
        margin-left: 25px;
      }
    }
  }

  div.section-light.podcast-section {
    div.visual-bow {
      position: absolute;
      z-index: 0;
      transform: scale(0.5) rotate(164deg) translate(-50px, 550px);
    }
    .content {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      margin-bottom: 25px;
    }
    div.player {
      padding: 0 25px;
      margin-bottom: 25px;
    }

    div.symbols {
      display: flex;
      justify-content: center;
      align-items: center;
      .symbol {
        width: 80px;
        height: 80px;
        border: 3px solid $supa-red;
        border-radius: 18px;
        color: $supa-red;
        font-size: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.25s, color 0.25s linear;

        &:hover {
          background-color: $supa-red;
          color: white;
        }
      }
    }
  }

  div.section-blue {
    background-color: $supa-blue;
    color: white;

    &.section-about {
      .content {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }
      p {
        margin-bottom: 25px;
      }
      .symbols {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15px;
        &:last-of-type {
          padding-bottom: 45px;
        }
        .symbol {
          width: 80px;
          height: 80px;
          border: 3px solid white;
          border-radius: 18px;
          color: white;
          font-size: 55px;
          display: flex;
          justify-content: center;
          align-items: center;

          transition: background-color 0.25s, color 0.25s linear;

          &:hover {
            background-color: white;
            color: $supa-blue;
          }
        }
      }
    }

    h1 {
      text-align: center;
      font-size: 72px;
    }

    p {
      font-family: "Jost", sans-serif;
      padding: 0 25px;
      font-size: 22px;
    }
  }

  div.section-red {
    background-color: $supa-red;
    color: white;

    h1 {
      text-align: center;
      font-size: 72px;
    }

    .content {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      font-family: "Jost", sans-serif;
      padding: 0 25px;
      font-size: 22px;
    }

    a {
      // font-weight: 700;
      color: white;
      text-decoration: underline;
    }
  }

  footer,
  .menu-footer {
    font-family: "Jost", sans-serif;
    width: 100%;
    background-color: rgb(61, 61, 61);
    color: white;

    div.footer-content {
      padding-top: 15px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      font-weight: 700;
      font-size: 48px;
      padding-left: 15px;
      padding-bottom: 15px;

      p {
        opacity: 0.6;
        margin-bottom: -10px;
        &.footer-text {
          font-weight: 500;
          opacity: 0.3;
          margin-bottom: 0px;
          font-size: 16px;

          a {
            color: white;
            text-decoration: none;
            font-style: italic;
          }

          span {
            opacity: 1;
            color: $supa-red;
          }
        }
      }
    }
  }
}

h1,
h2 {
  font-family: "Jost", sans-serif;
}

.ml-10 {
  margin-left: 25px;
}

.button {
  width: 90%;
  height: 75px;
  border: 4px solid $supa-blue;
  border-radius: 12px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    font-weight: 700;
    color: $supa-blue;
    font-family: "Jost", sans-serif;
    font-size: 24px;
    text-decoration: none;
  }
}

div.section-faq {
  padding-bottom: 30px;
  div.accordion {
    margin-top: 20px;
  }
}
div.accordion {
  border: 1px solid white;
  border-radius: 15px;
  div.entry {
    &-heading {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-bottom: 1px solid white;
      div.toggle-question {
        margin-left: auto;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s;

        &.collapsed {
          transform: rotateX(180deg);
        }
      }

      &.last {
        border-bottom: none;
      }
    }
    &-content {
      display: grid;
      grid-template-rows: 1fr;
      transition: grid-template-rows 0.2s linear;
      // background-color: white;
      // color: black
      border-bottom: 1px solid white;
      // height: 0;
      // overflow: hidden;
      padding: 15px 0;
      &.collapsed {
        border-bottom: none;
        overflow: hidden;
        padding: unset;
        grid-template-rows: 0fr;
        p {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1249px) {
  .story-section > div.visual-bow {
    display: none;
  }
}

@media only screen and (max-width: 590px) {
  .podcast-section > div.visual-bow {
    display: none;
  }
}

@media only screen and (min-width: 590px) {
  html {
    header {
      div.intro {
        img {
          max-width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 905px) {
  div.visual-ring {
    display: none;
  }
  div.section-faq {
    div.accordion {
      width: calc(100% - 30px);
      margin-left: 14px;
    }
  }
}

@media only screen and (max-width: 549px) {
  .story-section {
    .story,
    .story.alt {
      .content {
        .description {
          flex-direction: column-reverse !important;

          img {
            margin-right: auto !important;
            margin-left: auto !important;
            max-width: 80% !important;
          }
        }
      }
    }
  }
}
